<template>
  <div class="w-4/5 mx-auto">
    <div class="mx-4">
      <div class="text-3xl text-left text-body-4 my-6 font-medium">
        ZÁSADY OCHRANY SOUKROMÍ A ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ
      </div>
      <div class="text-2xl text-left text-body-4 my-2 font-medium">
        Zásady ochrany soukromí společnosti JoViRo, s.r.o., se sídlem
        Petrohradská 1486/6, Vršovice, 101 00 Praha 10, IČO: 11721669, zapsanou
        do obchodního rejstříku vedeného Městským soudem v Praze pod sp. zn. C
        353475 jako provozovatele internetového portálu www.maklerenechci.cz
        (dále jen „Joviro“ nebo „my“).
      </div>
      <br />
      <p>
        Naše služby lze využívat mnoha způsoby, můžete používat webovou aplikaci
        v prohlížeči Vašeho PC, nebo chytrého mobilního telefonu, můžete
        prohlížet naše stránky nebo se obrátit na naši podporu. Když využíváte
        naše služby nebo nám jinak poskytujete informace o své osobě, například
        když vytváříte svůj profil,
        <b
          >informace o vás používáme k tomu, abychom mohli řádně poskytovat své
          služby, abychom své služby zlepšovali a poskytovali vám relevantní
          odpovědi na vaše otázky.</b
        >
      </p>
      <br />
      <p>
        <b
          >Naše služby nejsou určeny pro děti mladší 16 let. Nezpracováváme
          osobní údaje dětí mladších 16 let. Pokud vám není alespoň 16 let,
          nepoužívejte naše internetové stránky ani mobilní aplikaci bez svolení
          a dohledu svého zákonného zástupce.</b
        >
      </p>
      <br />
      <p>
        Je důležité, abyste při využívání našich služeb plně rozuměli tomu, jak
        a proč zpracováváme vaše údaje, a abyste si byli vědomi svých práv.
      </p>
      <br />
      <p>
        V těchto zásadách ochrany soukromí společnosti Joviro (dále jen
        „<b>Zásady</b>“) uvádíme:
      </p>
      <br />
      <li>jaké údaje shromažďujeme a jakým způsobem</li>
      <li>jak shromážděné údaje používáme a na jakém právním základě</li>
      <li>jak dlouho zpracováváme shromážděné údaje</li>
      <li>kdo má ke shromážděným údajům přístup</li>
      <li>
        jaká máte práva v souvislosti se shromážděnými údaji a jak je uplatnit
      </li>
      <br />
      <div class="text-2xl text-left text-body-4 pb-4">
        JAKÉ ÚDAJE SHROMAŽĎUJEME A JAKÝM ZPŮSOBEM?
      </div>
      <p>
        Shromažďujeme pouze údaje o osobách, které se pomocí stránek nebo
        aplikace maklerenechci snaží prodat svojí nemovitost svými vlastními
        silami a tím ušetřit peníze za drahého živého makléře
        („<b>Uživatel</b>“).
      </p>
      <br />
      <p>Pokud jste <b>Uživatel</b>, shromažďujeme o vás tyto údaje:</p>
      <br />
      <li>
        <b>Údaje, které nám poskytnete.</b> To zahrnuje vaše osobní údaje, které
        nám poskytnete, když nás kontaktujete, když se přihlašujete do systému
        meklerenechci nebo když pořádáte o podporu. Obvykle nám tyto údaje
        poskytnete tím, že vyplníte a odešlete formulář na našich internetových
        stránkách, tím, že si vytvoříte profil na stránkách maklerenechci pomocí
        vašeho účtu, tím, že na Váš profil u nás přihlásíte. Tímto způsobem nám
        můžete poskytnout své jméno, příjmení, adresu bydliště, rodné číslo,
        datum narození, obrázek k Vašemu obydlí, e-mailovou adresu, telefonní
        číslo. Pokud jste se obrátili na naši zákaznickou podporu, shromažďujeme
        údaje o vašich osobních požadavcích. Abychom zlepšili poskytování svých
        služeb a zákaznickou podporu, používáme také záznamy vašich telefonních
        hovorů, pokud se nám rozhodnete zanechat hlasovou zprávu.
      </li>
      <br />
      <li>
        <b>Údaje, které získáváme, když využíváte naše služby.</b> Údaje o tom,
        jak používáte naše služby, shromažďujeme na základě různých skutečností,
        od návštěv našich internetových stránek po užívání systému
        maklerenechci. Jedná se o tyto údaje:
      </li>
      <div class="p-4">
        <li>
          Údaje o vaší aktivitě na našich internetových stránkách. K analýze
          provozu na našich internetových stránkách používáme soubory cookies.
          Používáme jak funkční, tak preferenční, analytické a rovněž
          marketingové cookies. Když se pohybujete na našich internetových
          stránkách, zaznamenáváme vaši aktivitu, jako například počet
          zhlédnutých stránek, délka návštěvy, jaké stránky byly navštíveny,
          opakované návštěvy apod. Tyto údaje nikdy nespojujeme s žádnými vašimi
          osobními údaji, jako například s údaji, které vyplníte do formulářů na
          našich internetových stránkách, nebo s vaší IP adresou. Více informací
          o používání souborů cookies naleznete v příloze B těchto Zásad.
        </li>
      </div>
      <div class="p-4 pt-0">
        <li>
          Údaje o vaší aktivitě v systému maklerenechci. Když používáte náš
          systém, získáváme údaje o vašich nemovitostech, o službách, které
          vyžadujete a o způsobu inzerce, který jste si vybrali.
        </li>
      </div>
      <div class="p-4 pt-0">
        <li>
          Údaje o vašem zařízení. Z hlášení o nečekaném ukončení aplikace a z
          technické podpory získáváme údaje o tom, jakou verzi aplikace
          používáte, o Vašem zařízení včetně operačního systému, hardwaru,
          záznamy z logů o nečekaném ukončení a jiné údaje nezbytné k tomu,
          abychom mohli zlepšovat své výrobky a služby a odstraňovat chyby.
          Pokud se připojujete prostřednictvím webové aplikace, shromažďujeme
          údaje o vašem operačním systému, prohlížeči, délce trvání relace,
          hlavičce user-agent, URL a drobečkové navigaci. Můžeme také
          shromažďovat a spojovat údaje s vaší IP adresou. Nepoužíváme IP adresy
          k tomu, abychom spojili jakékoli údaje o zařízení s konkrétní fyzickou
          osobou.
        </li>
      </div>
      <div class="text-2xl text-left text-body-4 pb-4">
        JAK SHROMÁŽDĚNÉ ÚDAJE POUŽÍVÁME A NA JAKÉM PRÁVNÍM ZÁKLADĚ?
      </div>
      <p>
        Shromažďované informace používáme k tomu, abychom vám mohli poskytovat
        své služby, zejména abychom vám umožnili se připojit k systému
        maklerenechci a správně s ním pracovat, abychom vám dali příležitost
        poskytnout o Vaší nemovitosti co nejrelevantnější informace, abyste
        následně mohli naplno využívat všechny námi ponoukané služby a projít
        procesem prodeje nemovitosti co nejpohodlněji.
      </p>
      <br />
      <p>
        Shromážděné údaje včetně vašich osobních údajů dále používáme za účelem
        ochrany oprávněných zájmů sebe a ostatních Uživatelů našich služeb.
      </p>
      <br />
      <p>
        <b
          >Můžeme použít vaši e-mailovou adresu k zasílání obchodních sdělení o
          našich službách, jako jsou oznámení o plánovaných změnách nebo
          vylepšeních.</b
        >
      </p>
      <br />
      <p>
        Údaje získané ze souborů cookies používáme ke zlepšování uživatelské
        zkušenosti a celkové kvality našich služeb. Za tímto účelem využíváme
        služby externích poskytovatelů jako například Google Analytics a jiné
        služby uvedené v příloze B těchto Zásad.
      </p>
      <br />
      <p>Právní základ pro zpracování vašich osobních údajů:</p>
      <br />
      <li>
        zpracováváme vaše osobní údaje taktéž za účelem plnění naší vzájemné
        smlouvy, plnění našich právních povinností a ochrany našich oprávněných
        zájmů, ale vzhledem k tomu, že vaše osobní údaje předáváme také dalším
        správcům údajů – poskytovatelům služeb, které jste si u nás objednali,
        potřebujeme k tomu váš souhlas.
        <b
          >Vzhledem k tomu, že poskytování informací o Vás dalším poskytovatelem
          služeb je pro nás důležité, nebudeme Vám moci poskytovat své služby,
          pokud nám nedáte své svolení s předáváním údajů Ostatním
          poskytovatelům služeb nebo poskytovatelům inzerce.</b
        >
      </li>
      <br />
      <p>
        <b>
          Ujišťujeme vás, že kdykoli budeme chtít použít vaše údaje pro účely,
          které nejsou uvedené v těchto Zásadách, vždy vás předem upozorníme a
          dáme vám příležitost přestat naše služby využívat.
        </b>
      </p>
      <br />
      <p>Vaše osobní údaje zpracováváme na základě těchto oprávněných zájmů:</p>
      <br />
      <li>
        abychom vás mohli kontaktovat v kontextu našich vzájemných obchodních
        vztahů včetně přímého marketingu
      </li>
      <li>zvyšování kvality našich služeb</li>
      <li>oprava chyb, k nimž dochází při užívání našich služeb</li>
      <li>
        pořizování záloh a zajištění možnosti obnovy systému a údajů v případě
        výpadku služby
      </li>
      <li>
        ochrana právních nároků společnosti Joviro a našich ostatních zákazníků
      </li>
      <li>
        hodnocení kvality našich služeb na základě Vámi vyplněného dotazníku o
        spokojenosti
      </li>
      <li>
        hodnocení úspěšnosti marketingových akcí, kterých jste se zúčastnili
      </li>
      <li>efektivní zákaznická podpora a realizace</li>
      <br />
      <div class="text-2xl text-left text-body-4 pb-4">
        JAK DLOUHO ZPRACOVÁVÁME SHROMÁŽDĚNÉ ÚDAJE?
      </div>
      <p>
        Vaše osobní údaje vždy uchováváme a zpracováváme pouze po dobu nezbytně
        nutnou k naplnění účelu, pro který byly shromážděny. Například, pokud se
        rozhodnete smazat svůj účet u nás, budeme vaše osobní údaje uchovávat po
        dobu dalších 30 dnů, pro případ, že se rozhodnete svůj účet obnovit, a
        po uplynutí této lhůty vaše osobní údaje odstraníme.
      </p>
      <br />
      <p>
        Upozorňujeme, že nemůžeme smazat údaje, které si o vás mohli uložit
        další poskytovatelé služeb. O výmaz těchto údajů musíte požádat přímo
        příslušných poskytovatelů.
      </p>
      <br />
      <p>
        Upozorňujeme, že v některých případech můžeme některé Vaše osobní údaje
        uchovávat po delší dobu, zejména pokud to vyžaduje zákon (například
        předpisy daňového práva) nebo pokud to bude třeba pro ochranu našich
        oprávněných zájmů (například v případě, že mezi námi probíhá soudní spor
        a my potřebujeme tyto údaje, abychom prokázali, že jste byli naším
        zákazníkem). V těchto případech uchováváme pouze osobní údaje nezbytné
        pro tyto účely a vymažeme je, jakmile to bude možné.
      </p>
      <br />
      <div class="text-2xl text-left text-body-4 pb-4">
        KDO MÁ PŘÍSTUP K VAŠIM OSOBNÍM ÚDAJŮM?
      </div>
      <p>
        Společnost Joviro se stará o ochranu Vašich osobních údajů a nikdy vaše
        osobní údaje neprodává za úplatu třetím osobám.
      </p>
      <br />
      <p>
        Za výše uvedenými účely můžeme také v nezbytném rozsahu předávat vaše
        osobní údaje třetím osobám (vizte níže).
      </p>
      <br />
      <p>
        <b
          >Jak je vysvětleno výše, vaše osobní údaje budou sdíleny s
          poskytovateli služeb, které si na našem portálu vyberete.</b
        >
      </p>
      <br />
      <p>
        Vaše osobní údaje můžeme dále sdílet s poskytovateli cloudových služeb,
        kteří zajišťují předávání dat mezi námi a Vámi, našimi servisními
        techniky a partnery, kteří poskytují služby podpory a údržby, našimi
        developery, právními poradci pro případ přípravy smlouvy nebo řešení
        sporu a externím účetním a daňovým poradcům.
      </p>
      <br />
      <p>
        Vaše osobní údaje zpracováváme na území Evropské unie a Evropského
        hospodářského prostoru a předáváme je svým partnerům ve Spojených
        státech amerických v souladu s platnými právními předpisy. Pokud chcete
        vědět víc o tom, kde se nachází vaše konkrétní osobní údaje, můžete nás
        kdykoli kontaktovat na adrese <b>gdpr@joviro.io</b> a my Vaši žádost
        rádi vyřídíme.
      </p>
      <br />
      <p>
        <b
          >Vaše osobní údaje předáváme třetím osobám pouze na základě
          odpovídajících smluv s těmito osobami, abychom mohli sledovat, jak
          tyto třetí osoby zachází s vašimi osobními údaji.</b
        >
      </p>
      <br />
      <div class="text-2xl text-left text-body-4 pb-4">
        JAK ZAJIŠŤUJEME BEZPEČNOST OSOBNÍCH ÚDAJŮ
      </div>
      <p>
        Zajistit bezpečnost vašich osobních údajů je pro nás důležitá povinnost
        a závazek. Snažíme se proto přijmout nejlepší možná bezpečnostní
        opatření a zabránit zneužití nebo jinému neoprávněnému zásahu do vašich
        osobních údajů. Abychom zajistili bezpečnost vašich osobních údajů,
        přijali jsme tato opatření:
      </p>
      <br />
      <li>
        <b>Zásada závislosti na míře rizika.</b> Budeme pravidelně přezkoumávat
        rizika pro bezpečnost informací v souvislosti s osobními údaji, abychom
        zajistili, že je naše zabezpečení osobních údajů na dostatečné úrovni.
      </li>
      <li>
        <b>Organizační bezpečnost.</b> Klademe důraz na zabezpečení vašich
        osobních údajů proti riziku lidské chyby.
      </li>
      <li>
        <b>Technická opatření.</b> Abychom zajistili bezpečnost Vašich osobních
        údajů, zavedli jsme důležitá technická opatření, zejména:
      </li>
      <div class="p-4">
        <li>antivirové řešení k ochraně serveru před malwarem</li>
        <li>
          řešení bezpečnosti sítě spojující firewally, nastavení sítě a další
          technologie
        </li>
        <li>šifrování přenosů údajů u nás pomocí protokolu HTTPS</li>
        <li>
          veškeré osobní údaje jsou uloženy v SQL databázi na serveru na území
          EU/EHS
        </li>
        <li>zálohujeme kritickou infrastrukturu a data</li>
      </div>
      <li>
        <b>Fyzické zabezpečení.</b> Neukládáme osobní údaje ve fyzické podobě.
      </li>
    </div>
    <br />
    <div class="text-2xl text-left text-body-4 pb-4">VÝKON VAŠICH PRÁV</div>
    <p>
      V souvislosti s naším zpracováním vašich osobních údajů máte tato práva:
    </p>
    <br />
    <li>právo na přístup k osobním údajům</li>
    <li>právo na opravu</li>
    <li>právo na výmaz („právo být zapomenut“)</li>
    <li>právo na omezení zpracování</li>
    <li>právo na přenositelnost osobních údajů</li>
    <li>právo vznést námitky proti zpracování</li>
    <li>právo podat stížnost proti zpracování osobních údajů</li>
    <br />
    <p>
      Tato práva jsou zde vysvětlena, abyste měli jasnější představu o tom, co
      znamenají. Veškerá svá práva můžete uplatnit tak, že nám napíšete na
      adresu <b>gdpr@joviro.io</b>. Můžete podat stížnost k dozorovému úřadu,
      kterým je Úřad pro ochranu osobních údajů (www.uoou.cz).
    </p>
    <br />
    <p>
      <b>Právem na přístup</b> se rozumí, že nás můžete kdykoli požádat abychom
      potvrdili, zda zpracováváme osobní údaje, které se Vás týkají, či nikoli,
      a pokud ano, máte právo na přístup k veškerým takovým údajům a na
      informace o tom, za jakým účelem a v jakém rozsahu jsou zpracovávány, kdo
      k nim má přístup, jak dlouho je budeme zpracovávat, zda máte právo na
      opravu, výmaz, omezení zpracování nebo právo vznést námitku proti
      zpracování osobních údajů, odkud jsme vaše osobní údaje získali a zda je
      zpracováváme za účelem automatizovaného rozhodování včetně profilování.
    </p>
    <br />
    <p>
      <b>Právem na opravu</b> se rozumí, že nás můžete kdykoli požádat abychom
      opravili či doplnili Vaše osobní údaje, pokud jsou nepřesné nebo neúplné.
    </p>
    <br />
    <p>
      <b>Právem na výmaz</b> se rozumí, že jsme povinni vymazat Vaše osobní
      údaje, pokud i) již nejsou potřebné pro účely, pro něž byly shromážděny
      nebo jinak zpracovány; ii) zpracování je nezákonné; iii) vznášíte námitku
      proti zpracování a neexistují žádné převažující oprávněné důvody pro
      zpracování; iv) jsme povinni tak učinit na základě zákonné povinnosti nebo
      v) odvolali jste svůj souhlas se zpracováním svých osobních údajů (za
      předpokladu, že tyto osobní údaje byly zpracovávány výhradně na základě
      vašeho souhlasu).
    </p>
    <br />
    <p>
      <b>Právo na přenositelnost </b> znamená, že máte právo získat osobní
      údaje, které se Vás týkají a které jste nám poskytli na základě souhlasu
      nebo smlouvy, a které jsou zároveň zpracovávány automatizovaně, a to ve
      strukturovaném, běžně používaném a strojově čitelném formátu, a právo na
      to, aby byly tyto osobní údaje předány přímo jinému správci.
    </p>
    <br />
    <p>
      <b>Právem vznést námitku </b> se rozumí, že máte právo vznést námitku
      proti zpracování Vašich osobních údajů, které zpracováváme na základě
      našich oprávněných zájmů, zejména pro účely přímého marketingu. Pokud
      nesouhlasíte se zpracováním pro účely přímého marketingu, Vaše osobní
      údaje nadále nebudou pro tyto účely zpracovávány.
    </p>
    <br />
    <p><b>Tyto Zásady nabývají účinnosti dne 1.6.2022</b></p>
    <br />
    <div class="text-3xl text-left text-body-4 my-6 font-medium">
      POUŽÍVÁNÍ SOUBORŮ COOKIES NA NAŠICH INTERNETOVÝCH STRÁNKÁCH
    </div>
    <br />
    <div class="text-2xl text-left text-body-4 pb-4">
      CO JSOU TO SOUBORY COOKIES?
    </div>
    <p>
      Soubory cookies jsou malé textové soubory, které internetové stránky uloží
      do vašeho počítače či mobilního zařízení, když je začnete používat.
      Stránky si například budou pamatovat vaše preference a úkony, které jste
      provedli (jako například přihlášení, jazyk, velikost písma a jiná
      nastavení zobrazení), aby nebylo potřeba je vždy znovu nastavovat a aby
      bylo prohlížení stránek snazší a pohodlnější.
    </p>
    <br />
    <div class="text-2xl text-left text-body-4 pb-4">
      PROČ POUŽÍVÁME SOUBORY COOKIES?
    </div>
    <p>
      Naše internetové stránky, jako skoro všechny velké internetové stránky na
      světě, používají Soubory cookies k tomu, aby jejich prohlížení bylo
      uživatelsky co nejpohodlnější. Soubory cookies zejména pomáhají:
    </p>
    <br />
    <li>naším internetovým stránkám fungovat tak, jak očekáváte</li>
    <li>zrychlit a lépe zabezpečit naše internetové stránky</li>
    <li>umožnit vám sdílet zprávy na sociálních médiích</li>
    <li>neustále zlepšovat naše internetové stránky</li>
    <li>zefektivnit náš marketing</li>
    <br />
    <p>Soubory cookies <b>nepoužíváme k/ke</b>:</p>
    <br />
    <li>shromažďování zvláštních kategorií osobních údajů</li>
    <li>předávání osobních údajů třetím osobám</li>
    <li>získávání provize z prodeje</li>
    <br />
    <p>
      Níže se dozvíte více o všech různých Souborech cookies, které používáme.
    </p>
    <br />
    <div class="text-2xl text-left text-body-4 pb-4">
      VÍCE INFORMACÍ O SOUBORECH COOKIES
    </div>
    <br />
    <p>
      <b
        >SOUBORY COOKIES SLOUŽÍCÍ K TOMU, ABY INTERNETOVÉ STRÁNKY SPRÁVNĚ
        FUNGOVALY</b
      >
    </p>
    <br />
    <p>
      Některé Soubory cookies používáme k tomu, aby naše internetové stránky
      správně fungovaly – funkční cookies, například:
    </p>
    <br />
    <li>aby zjistily, zda jste se přihlásili, či nikoli</li>
    <li>aby si pamatovaly vaše nastavení vyhledávání</li>
    <li>aby si pamatovaly, zda jste přijali naše obchodní podmínky</li>
    <li>aby vám umožnily přidávat na naše internetové stránky komentáře</li>
    <li>
      aby si pamatovaly, jestli jsme vám už pokládali určitou otázku (např. že
      jste se rozhodli nepoužívat naši aplikaci nebo vyplnit dotazník)
    </li>
    <br />
    <p>
      Bohužel, jediný způsob, jak zabránit používání těchto Souborů cookies, je
      přestat používat naše internetové stránky nebo úplně zakázat Soubory
      cookies ve vašem prohlížeči.
    </p>
    <br />
    <p>
      <b>
        SOUBORY COOKIES SLOUŽÍCÍ K MARKETINGOVÝM ANALYTICKÝM A PREFERENČNÍM
        ÚČELŮM</b
      >
    </p>
    <br />
    <p>
      Při návštěvě našich stránek budete dotázáni, které z výše uvedených druhů
      cookies budete na našich stránkách využívat. Můžete jejich užívání
      odmítnout, nicméně tím pravděpodobně dojde k omezení některých funkcí. V
      případě dotazů ohledně dopadů povolení těchto cookies nás kontaktujte na
      adrese <b>gdpr@joviro.io</b>.
    </p>
    <br />
    <p>Naše internetové stránky používají také Soubory cookies třetích osob.</p>
    <br />
    <div class="text-2xl text-left text-body-4 pb-4">
      ZÁKAZ NEBO OMEZENÍ SOUBORŮ COOKIES
    </div>
    <p>
      Obvykle můžete zakázat Soubory cookies také tím, že nastavíte svůj
      prohlížeč tak, aby je odmítal. Pokud tak učiníte, pravděpodobně tím
      omezíte funkčnost našich a mnoha dalších internetových stránek, protože
      Soubory cookies jsou běžnou součástí většiny moderních internetových
      stránek.
    </p>
  </div>
</template>

<script>
export default {
  name: "GeneralDataProtectionRegulation",
};
</script>
